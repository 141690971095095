import { FunctionComponent, Fragment } from "react";
import classNames from "classnames";
import { world } from "./Header.module.scss";
import faviconPng from "../../../static/favicon.png";

const Header: FunctionComponent = () => {
  // const { t } = useTranslation("site");
  // const { locale } = useLocalization();
  // const rootPath = getRootPath(locale);
  const companyYears = new Date().getFullYear() - 2011; // 09.11.2011

  return (
    <>
      <div
        className="rs-banner style3 rs-rain-animate modify1"
        style={{ padding: "30px 0" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="banner-content banner-padding"
                style={{ paddingTop: "30px" }}
              >
                <div
                  className="rs-videos"
                  style={{ display: "flex", marginBottom: "50px" }}
                >
                  <div
                    className="animate-border white-color style3"
                    style={{ lineHeight: "80px", display: "inline-flex" }}
                  >
                    <div
                      className="popup-border popup-videos"
                      style={{
                        display: "flex",
                        position: "relative",
                        top: 0,
                        left: "40px",
                        right: 0,
                        bottom: 0,
                        marginRight: "20px",
                      }}
                    >
                      <img src={faviconPng} alt="" width="80" height="80" />
                    </div>
                    <div style={{ fontSize: "2.6rem", fontWeight: 500 }}>
                      Hyper Soft
                    </div>
                  </div>
                </div>
                <h1 className="title">
                  We Are Digital Agency & Technology Solution
                </h1>
                <p className="desc">
                  We are leading technology solutions providing company all over
                  the world doing over {companyYears} years.
                </p>
              </div>
            </div>
            <div className="col-lg-6 text-center hidden-md">
              <img
                className={world}
                src="/img/world.svg"
                alt=""
                loading="lazy"
                width="550"
                height="550"
              />
            </div>
          </div>
        </div>

        <div className="line-inner style2">
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </div>
      </div>
    </>
  );
};
export default Header;
