import { Fragment, FunctionComponent } from "react";
import classNames from "classnames";
import {
  address_widget,
  desc,
  footer_bottom,
  footer_logo,
  footer_social,
  footer_top,
  rs_footer,
  site_map,
  widget_title,
} from "./Footer.module.scss";
import linkedInSvg from "../../../static/svg/linkedin.svg";
import scheduleSvg from "../../../static/svg/schedule.svg";
import placeSvg from "../../../static/svg/place.svg";
import phoneSvg from "../../../static/svg/phone.svg";
import mailSvg from "../../../static/svg/mail.svg";
import logoPng from "../../../static/img/logo.png";

const Footer: FunctionComponent = () => {
  // const { t } = useTranslation(["site"]);
  // const { locale } = useLocalization();
  // const rootPath = getRootPath(locale);
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className={rs_footer}>
        <div className={footer_top}>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12 col-sm-12 footer-widget">
                <div className="mb-30">
                  <a href="#">
                    <img
                      className={footer_logo}
                      src={logoPng}
                      alt="logo"
                      width="235"
                      height="40"
                    />
                  </a>
                </div>
                <div className="textwidget pb-30">
                  <p>
                    Our goal is to improve your business and bring it within the
                    reach by means of simple software solutions.
                  </p>
                </div>
                <ul className={classNames(footer_social, "md-mb-30")}>
                  <li>
                    <a
                      href="https://www.linkedin.com/company/hypersoft11/"
                      target="_blank"
                      className="social-link"
                      rel="noreferrer"
                    >
                      <i>
                        <img
                          src={linkedInSvg}
                          alt="linkedin"
                          className="filter-5d768d"
                          width="22"
                          height="22"
                        />
                      </i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 pl-45 md-pl-15 md-mb-30">
                <h3 className={widget_title}>IT Services</h3>
                <ul className={site_map}>
                  <li>Web Development</li>
                  <li>Software Development</li>
                  <li>Mobile Development</li>
                  <li>Product Design</li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-12 col-sm-12 md-mb-30">
                <h3 className={widget_title}>Contact Info</h3>
                <ul className={address_widget}>
                  <li>
                    <i className="app-icon">
                      <img
                        src={placeSvg}
                        alt="place"
                        className="filter-106eea"
                        width="24"
                        height="24"
                      />
                    </i>
                    <div className={desc}>
                      6-а, Olesya Gonchara, BC-Neon City, 4th floor, Kherson,
                      Ukraine
                    </div>
                  </li>
                  {/* <li>
                    <i className="app-icon">
                      <img
                        src={phoneSvg}
                        alt="phone"
                        className="filter-106eea"
                        width="24"
                        height="24"
                      />
                    </i>
                    <div className={desc}>
                      <a href="tel:+380503810107">(+380) 503-810-107</a>
                    </div>
                  </li> */}
                  <li>
                    <i className="app-icon">
                      <img
                        src={mailSvg}
                        alt="mail"
                        className="filter-106eea"
                        width="24"
                        height="24"
                      />
                    </i>
                    <div className={desc}>
                      <a href="mailto:support@hypersoft.io">
                        support@hypersoft.io
                      </a>
                    </div>
                  </li>
                  <li>
                    <i className="app-icon">
                      <img
                        src={scheduleSvg}
                        alt="clock"
                        className="filter-106eea"
                        width="24"
                        height="24"
                      />
                    </i>
                    <div className={desc}>Opening Hours: 09:00 - 18:00</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={footer_bottom}>
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-6">
                <div className="copyright">
                  <p>&copy; 2011-{currentYear} All Rights Reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
