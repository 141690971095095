// extracted by mini-css-extract-plugin
export var rs_footer = "Footer-module--rs_footer--4Nkb1";
export var footer_top = "Footer-module--footer_top--31yVl";
export var footer_logo = "Footer-module--footer_logo--4YDSx";
export var widget_title = "Footer-module--widget_title--17g7F";
export var site_map = "Footer-module--site_map--2E-hD";
export var footer_social = "Footer-module--footer_social--2FXUC";
export var address_widget = "Footer-module--address_widget--1rKbb";
export var desc = "Footer-module--desc--2mR2p";
export var footer_bottom = "Footer-module--footer_bottom--SzCN-";
export var copyright = "Footer-module--copyright--2GL1M";
export var copyRightMenu = "Footer-module--copy-right-menu--uojfb";